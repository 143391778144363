<template>
    <div>
        <b-row no-gutters>
            <b-col lg="2" md="3" sm="12" xs="12">
                <sider-menu-items :items="menuSiderItems.items"/>
            </b-col>
            <b-col lg="10" md="9" sm="12" xs="12">
                <router-view></router-view>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import SiderMenuItems from "@/components/general/elements/menus/SiderMenuItems";

export default {
    name: "AdventureContent",
    components: {SiderMenuItems},
    props: {},
    data() {
        return {
            menuSiderItems: {
                // <-- user -->
                items: [
                    // <-- admin -->
                    {
                        type: 'text',
                        text: this.$t('adventure.sider_menu.adventure'),
                    },
                    {
                        type: 'item',
                        text: this.$t('adventure.sider_menu.purpose_types'),
                        path: '/adventure/purpose_types',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('adventure.sider_menu.price_units'),
                        path: '/adventure/price_units',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('adventure.sider_menu.category'),
                        path: '/adventure/categories',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('adventure.sider_menu.type'),
                        path: '/adventure/types',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('adventure.sider_menu.destination'),
                        path: '/adventure/destinations',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('adventure.sider_menu.experience'),
                        path: '/adventure/experiences',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$tc('adventure.route.main', 1),
                        path: '/adventure/routes',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'divider',
                    },

                    {
                        type: 'item',
                        text: this.$t('adventure.sider_menu.food_drinks'),
                        path: '/adventure/food_drinks',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('adventure.sider_menu.lodging'),
                        path: '/adventure/lodging',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('adventure.sider_menu.visit'),
                        path: '/adventure/visit',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('adventure.sider_menu.info'),
                        path: '/adventure/info',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('adventure.sider_menu.rent'),
                        path: '/adventure/rent',
                        disabled: true,
                        active: false,
                    },
                ]
            },
        }
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
